body {
    background-color: #fbfbfb;
  }
  @media (min-width: 991.98px) {
    main {
      padding-left: 240px;
    }
  }
  
  /* Sidebar */
  .sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 58px 0 0; /* Height of navbar */
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
    width: 240px;
    z-index: 600;
    overflow-y: auto;
  }
 
  
  @media (max-width: 991.98px) {
    .sidebar {
      width: 100%;
    }
  }
  .sidebar .active {
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  }
  
  .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  }
  /* Reset default styles for ul and li elements */
ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Styles for top-level menu items */
.list-unstyled.components li {
  margin-bottom: 10px; /* Adjust spacing between menu items */
}

.list-unstyled.components li a {
  display: block;
  padding: 8px 10px;
  color: #fff; /* Text color */
  text-decoration: none;
  background-color: #0d0f0f; /* Main background color */
  border-radius: 4px;
  transition: background-color 0.2s;
  box-shadow: none;
}

.list-unstyled.components li a:hover {
  background-color: #474747; /* Highlight on hover */
}

/* Styles for sub-menu items */
.list-unstyled.components li ul li {
  margin-left: 20px; /* Indent sub-menu items */
}

.list-unstyled.components li ul li a {
  background-color: #0d0f0f; /* Sub-menu item background color */
  border-radius: 3px;
}

.list-unstyled.components li ul li a:hover {
  background-color: #474747; /* Highlight sub-menu items on hover */
}

.home-heading h1{

  color: black !important;

}
@media (max-width : 768px){

  .position-sticky {
    position: sticky!important;
    margin-top: -53px !important;
}
}
