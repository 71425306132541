img {
    border-radius: 6%;
    text-align: center;
    /* margin-left: 241px; */
    width: 41%;
}

@media (max-width : 768px) {

    img {
        border-radius: 50%;
        text-align: center;
        margin-left: 42px;
        width: 74%;
        
    }
}

