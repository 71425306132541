.custom-card-cl-en {
    width:90%;
    background-color: #f5f5f5;
   margin:auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding:7px;
    transition: 0.3s;
    
  }
  .custom-card-cl-en {
    width: 90%;
    background-color: #f5f5f5;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 7px;
    transition: 0.3s;
    margin-top: 5rem;
    margin-left: 5rem;
}

@media(max-width : 988px){
    .custom-card-cl-en {
        width: 90%;
        background-color: #f5f5f5;
        margin: auto;
        border-radius: 10px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        padding: 7px;
        transition: 0.3s;
      
    }

}